import { environment } from "core/modules/config/appConfig"
import { logger } from "core/modules/logging/Logger"

type OkResult = [Blob, null]
type ErrorResult = [null, unknown]

const environments = {
  development: "http://localhost:8083/",
  staging: "https://v2.pdf.hintsa.io/latest/",
  production: "https://v2.pdf.hintsa.io/print/"
}
const baseUrl = environments[environment] ?? environments["production"]

const log = new logger("PDF")

export const printPdf = async (targetUrl: string): Promise<OkResult | ErrorResult> => {
  try {
    log.info("Printing to PDF", targetUrl)

    const url = new URL(baseUrl)
    url.searchParams.append("url", targetUrl)

    const response = await fetch(url.toString())
    if (!response.ok || response.status >= 400) {
      log.warning("Could not print PDF", { status: response.status, statusText: response.statusText })

      return [null, new Error(`Could not print PDF: ${response.status} ${response.statusText}`)]
    }

    const blob = await response.blob()

    return [blob, null]
  } catch (error) {
    log.error("Could not print PDF", error)

    return [null, error]
  }
}
